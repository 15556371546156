import React, { useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import { Helmet } from 'react-helmet'
import useScript from '../hooks/useScript'
import Layout from '../components/layout'

const importAll = (r, cache) => r.keys().forEach(
  (key) => cache[key] = r(key)
)
const imageFiles = {}
importAll(require.context(`../content/posts/`, true, /\.(png|gif|jpg)$/), imageFiles)
const transformImageUri = slug => uri => {
  const imgPath = `./${slug}/${uri.substring(2)}`
  const importedImg = imageFiles[imgPath]
  return importedImg ? importedImg.default : ''
}

const Divider = ({ text, margins = {} }) => <div
  className={`relative ${margins.b ? 'mb-5' : ''} ${margins.t ? 'mt-10' : ''}`}>
  <div className="absolute inset-0 flex items-center" aria-hidden="true">
    <div className="w-full border-t border-gray-300"></div>
  </div>
  <div className="relative flex justify-center">
        <span className="px-3 bg-white text-lg font-medium text-gray-900">
            {text}
        </span>
  </div>
</div>

const BlogPost = ({ pageContext: { post } }) => {
  const { slug, body: textContent, attributes } = post
  const bannerImg = 'http://images.waxcarvers.com.s3-website-eu-west-1.amazonaws.com/waxcarvers.png'
  const imageFile = imageFiles[`./${slug}/poster.png`] ? imageFiles[`./${slug}/poster.png`].default : bannerImg
  console.log(imageFile)
  useEffect(() => {
    window.idcomments_acct = '6ef129fa543e554b22482874064d328f'
    window.idcomments_post_id = slug
    window.idcomments_post_url = `https://www.waxcarvers.com/blog/post/${slug}`
    window.scrollTo(0, 0)
    return () => {
      delete window.idcomments_post_id
      delete window.idcomments_post_url
    }
  }, [slug])

  const itemListElement = [
    {
      '@type': 'ListItem',
      item: {
        '@id': 'https://www.waxcarvers.com/',
        name: 'Homepage',
      },
      position: 1,
    },
    {
      '@type': 'ListItem',
      item: {
        '@id': 'https://www.waxcarvers.com/Blog',
        name: 'Blog',
      },
      position: 2,
    },
  ]

  const richContent = {
    '@context': 'http://schema.org',
    '@type': 'Article',
    author: {
      '@type': 'Person',
      name: 'Sandy Pfaff',
      url: 'https://www.waxcarvers.com/about'
    },
    copyrightHolder: {
      '@type': 'Person',
      name: 'Sandy Pfaff',
    },
    copyrightYear: '2022',
    creator: {
      '@type': 'Person',
      name: 'Sandy Pfaff',
    },
    publisher: {
      '@type': 'Organization',
      name: 'Wax Carvers',
      logo: {
        '@type': 'ImageObject',
        url: bannerImg,
      },
    },
    datePublished: attributes.date,
    dateModified: attributes.date,
    description: attributes.description,
    headline: attributes.title,
    inLanguage: 'English',
    url: `https://www.waxcarvers.com/blog/post/${slug}`,
    name: attributes.title,
    image: {
      '@type': 'ImageObject',
      url: `https://www.waxcarvers.com/${imageFile}`,
    },
    mainEntityOfPage: `https://www.waxcarvers.com/blog/post/${slug}`,
  }

  itemListElement.push({
    '@type': 'ListItem',
    item: {
      '@id': `https://www.waxcarvers.com/blog/post/${slug}`,
      name: attributes.title,
    },
    position: 3,
  })
  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    description: 'Breadcrumbs list',
    name: 'Breadcrumbs',
    itemListElement,
  }
  useScript('https://www.intensedebate.com/js/genericCommentWrapperV2.js', 'body')
  return <Layout>
    <Helmet>
      <title>{attributes.title}</title>
      <meta name="description" content={attributes.description} />
      <meta property="og:title" content={`${attributes.title} | Wax Carvers`} />
      <meta property="og:image" content={`https://www.waxcarvers.com/${imageFile}`} />
      <meta property="og:url" content={`https://www.waxcarvers.com/blog/post/${slug}`} />
      <meta property="og:description" content={attributes.description} />

      <meta itemProp="name" content={`${attributes.title} | Wax Carvers`} />
      <meta itemProp="description" content={attributes.description} />

      <meta property="twitter:title" content={`${attributes.title} | Wax Carvers`} />
      <meta property="twitter:description" content={attributes.description} />
      <meta property="twitter:card" content={`https://www.waxcarvers.com/${imageFile}`} />
      <meta property="twitter:image:src" content={`https://www.waxcarvers.com/${imageFile}`} />

      <script type="application/ld+json">{JSON.stringify(richContent)}</script>
      <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>

    </Helmet>
    <div className="relative overflow-hidden">
      <div className="relative pt-2 pb-12 lg:pb-20">

        <div className="mt-5 mx-auto max-w-screen-xl px-4 sm:px-6 md:mt-8 lg:mt-10">
          <div className="prose prose-sm sm:prose lg:prose-lg mx-auto mt-20">
            <ReactMarkdown
              transformImageUri={transformImageUri(slug)}
              allowDangerousHtml
              children={textContent} />
          </div>

          <Divider margins={{ t: true, b: true }} />
          <div className="flex container justify-center">
            <style dangerouslySetInnerHTML={{
              __html: `
            
  @media screen and (min-width: 800px) {
    #idc-container-parent #idc-container {
      width: 720px !important;
    }
  }
  #idc-container-parent #idc-container,
  #idc-container-parent #idc-container div {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 14px;  
  }
  #idc-container .idc-btn_s {
      background-color: #140AD8;
      padding: 4px 8px !important;
      border: 1px solid black !important;  
      border-radius: 5px;
  }  
  
  #idc-container .idc-c .idc-c-t div {
      margin: 10px 0 !important;
  }    
  #idc-container .idc-c .idc-c-h {
      background: none !important;
      padding: 6px !important;
      font-weight: bold !important;
  }  
  #idc-container .idc-c .idc-i span {
      font-weight: bold !important;
  }
  #idc-container #IDNewThreadSubmitLI {
      background-color: #140AD8;
      padding: 12px 20px 12px 12px;
      border: 1px solid black;  
      border-radius: 5px;
  }
  #idc-container #IDReplyDivSubmitLIButton {
      background-color: #140AD8;
      padding: 4px 8px !important;
      border: 1px solid black;  
      border-radius: 5px;
  }   
  #idc-container [href="javascript: hideReply();"] span:nth-child(2) {
      background-color: white;
      padding: 4px 8px !important;
      border: 1px solid black;  
      border-radius: 5px;
      color: black;
  }
  #idc-container .idc-r {
      background-color: #140AD8;
      color: white;
      margin-bottom: 8px !important;
  } 
  #idc-container .idc-b div {
      margin: 48px !important;
  } 
  #idc-container [id^="IDCommentPostReplyLink"] {
      margin-bottom: 15px !important;
  }
  #idc-container .idc-b a {
      margin: 48px !important;
  }
  #idc-container .idc-b em {
      margin: 48px !important;
  }
  #idc-container .idc-btn-l {
      background-color: #140AD8;
  }
#idc-container .idc-btn_l span, #idc-container .idc-btn_s span {
  background: none; 
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; 
}

#idc-container #IDCNavGuest2
{
    display: none !important;
}
#idc-container #IDCNavGuestReply2 span
 {
    display: none !important;
}
#idc-container #IDCPostNavReply ul
 {
    display: none !important;
}
#idc-container #IDCNavList {
    display: none;
}
#idc-container #IDCColumnName input
 {
    font-size: 14px;
    line-height: 24px;
    border-radius: 6px;
}
#idc-container #IDCColumnNameReply input
 {
    font-size: 14px;
    line-height: 24px;
    border-radius: 6px;
}
#idc-container #IDCColumnEmailReply input {
    font-size: 14px;
    line-height: 24px;
    border-radius: 6px;
}
#idc-container #IDCColumnURLLabel {
    display: hidden;
}

`
            }} />
            <div className="flex-grow" id="idc-container-parent">
              <span className="flex-grow" id="IDCommentsPostTitle" style={{ display: 'none' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
}

export default BlogPost
